export const facArrowRoundedRight = {
  prefix: 'fac',
  iconName: 'arrow-rounded-right',
  icon: [
    60,
    60,
    [],
    null,
    'm58.99 28.4c-11.57 0-20.99-9.42-20.99-20.99h-3.2c0 8.97 4.92 16.82 12.19 20.99h-45.99v3.2h46c-7.28 4.18-12.19 12.02-12.19 21h3.2c0-11.58 9.42-21 21-21v-3.2s0 0 0 0z',
  ],
}
