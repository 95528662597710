export const facChevronRoundedRight = {
  prefix: 'fac',
  iconName: 'chevron-rounded-right',
  icon: [
    36,
    36,
    [],
    null,
    'm11.6 1h-2.8c0 7.67 4.69 14.24 11.36 17-6.67 2.76-11.36 9.33-11.36 17h2.8c0-8.62 6.98-15.6 15.6-15.6v-2.8c-8.62 0-15.6-6.98-15.6-15.6z',
  ],
}
