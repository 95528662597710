import { dom, library } from "@fortawesome/fontawesome-svg-core";
import 'bootstrap';
import "../sass/app.scss";
import * as fac from "./icons";

library.add(fac);

dom.watch();

var openMobileNavbar = false;

var openConceptsDropdown = true;

let toggleNavBar = (event) => {
  openMobileNavbar = !openMobileNavbar;
  document
    .getElementById("mobile-header")
    .classList.toggle("open", openMobileNavbar);
  document
    .getElementById("body")
    .classList.toggle("over-hidden", openMobileNavbar);
};

let toggleConceptsDropdown = (event) => {
  console.log("toggleConceptsDropdown");
  openConceptsDropdown = !openConceptsDropdown;
  document
    .getElementById("concepts-dropdown")
    .classList.toggle("d-none", openConceptsDropdown);
};
document

  .getElementById("mobile-header-toggle")
  ?.addEventListener("click", toggleNavBar);

document
  .getElementById("concepts-dropdown-button")
  ?.addEventListener("click", toggleConceptsDropdown);

document
  .getElementById("mobile-header-toggle-in-menu")
  ?.addEventListener("click", toggleNavBar);

document
  .getElementById('meetingBookingRequestFormCollapse')
  ?.addEventListener('shown.bs.collapse', (event) => {
    event.target.querySelector('input:not([type="hidden"])').focus();
  });
